import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment-timezone';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // token: '',
    userLogged: JSON.parse(localStorage.getItem('userLogged')),
    snackbarData: {
      error: false,
      message: '',
      variant: ''
    },
    marginLeftNavbar: '225px'
  },
  getters: {
    isLogged(state) {
      return state.userLogged ?? undefined
    },
    getUserLogged(state) {
      return state.userLogged
    },
    marginLeftNavbar(state) {
      return state.marginLeftNavbar;
    },
    userCan: (state) => (permissionName) => {
      const found = state.userLogged.permissions.find(permission => permission == permissionName)
      return found ? true : false
    },
    variantState: (state) => (ticketState) => {
      console.log(state.marginLeftNavbar)
      if(ticketState == 'Pendiente'){
          return 'danger'
      }
      if(ticketState == 'Desarrollo'){
          return 'warning'
      }
      if(ticketState == 'Finalizado'){
          return 'success'
      }
      return ''
    },
    variantPriority: (state) => (priority) => {
      console.log(state.marginLeftNavbar)
      if(priority == 'Bajo'){
          return 'success'
      }
      if(priority == 'Medio'){
          return 'warning'
      }
      if(priority == 'Alto'){
          return 'danger'
      }
    },
  },
  mutations: {

  },
  actions: {
    login({ state }, payload) {
      // state.token = payload
      // localStorage.setItem('token', payload)
      state.userLogged = payload
      localStorage.setItem('userLogged', JSON.stringify({ ...payload, dateLogged: moment().valueOf() }))
    },
    logout({ state }) {
      // state.token = null
      // localStorage.removeItem('token')
      state.userLogged = undefined
      localStorage.removeItem('userLogged')

    }
  }
})
