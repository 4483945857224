import axios from 'axios';
import { API_CONSTANTS } from '@/shared/generalConstants';

async function getAll(page,filters) {
    let response = await axios.post(API_CONSTANTS.ENDPOINTS.TICKETS+`?page=${page}`,filters)
    return response.data
}

async function getAllWithClosed(page,filters) {
    let response = await axios.post(API_CONSTANTS.ENDPOINTS.TICKETS+`?page=${page}&withClosed=true`,filters)
    return response.data
}

async function getTicketDetail(id) {
    let response = await axios.get(`${API_CONSTANTS.ENDPOINTS.TICKETS}/${id}`)
    return response.data
}

async function saveTicket(data){
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(API_CONSTANTS.ENDPOINTS.SAVE_TICKET, data,{
                headers: { "Content-Type": "multipart/form-data" },
            })
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function addCommentToTicket(id,data){
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${API_CONSTANTS.ENDPOINTS.TICKETS}/${id}/add-comment`, data)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

async function closeTicket(id) {
    let response = await axios.post(`${API_CONSTANTS.ENDPOINTS.TICKETS}/${id}/close`)
    return response.data
}

async function inProgressTicket(id) {
    let response = await axios.post(`${API_CONSTANTS.ENDPOINTS.TICKETS}/${id}/in-progress`)
    return response.data
}

async function assignTicket(id,data) {
    let response = await axios.post(`${API_CONSTANTS.ENDPOINTS.TICKETS}/${id}/assign`,data)
    return response.data
}

export default {
    getAll,
    getAllWithClosed,
    getTicketDetail,
    saveTicket,
    addCommentToTicket,
    closeTicket,
    inProgressTicket,
    assignTicket
}