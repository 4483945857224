export const API_CONSTANTS = {
    // BASE_URL: "http://localhost:8000/api",
    BASE_URL: "https://api-helpdesk-mthipd6hwr3x.alianzadx.com/api",
    ENDPOINTS: {
        AUTH: {
            LOGIN: "/auth/login",
            REGISTER: "/auth/register",
            LOGOUT: "/auth/logout"
        },
        GET_USER_LOGGED: "/user-logged",
        TICKETS: '/tickets',
        SAVE_TICKET: '/save-ticket',
        MAESTROS: {
            CAMPUS: '/masters/campus',
            PRIORITY: '/masters/priorities',
            DEPENDENCY: '/masters/dependencies',
            TICKETS_TYPE: '/masters/tickets-type',
            USERS: '/masters/users',
            RESPONSIBLES: '/masters/responsibles'
        }
    },

}