import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './interceptor/axiosInterceptor.js';
require('moment/locale/es');

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vue-select/dist/vue-select.css';
import moment from 'moment-timezone';
import './app.scss'

moment.tz.setDefault('America/Bogota');

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false


Vue.prototype.$newSnackbarError = (message) => {
  store.state.snackbarData = {
    error: true,
    variant: 'danger',
    message: message
  }
}

Vue.prototype.$newSnackbarWarning = (message) => {
  store.state.snackbarData = {
    error: true,
    variant: 'warning',
    message: message
  }
}

Vue.prototype.$newSnackbarSuccess = (message) => {
  store.state.snackbarData = {
    error: false,
    variant: 'success',
    message: message
  }
}

Vue.prototype.checkPermission = (permissionName) => {
  return store.getters.userCan(permissionName);
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
