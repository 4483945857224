<template>
    <b-container fluid>
        <b-row align-h="center">
            <b-col>
                <h4 class="text-left"># {{ $route.params.id }} -> {{ ticketData.title }}</h4>
                <h6>por {{ ticketData.userName }} en {{ ticketData.created_at | formatDate }}</h6>
                <span class="mr-4"><b>Tipo:</b> <a href="#">{{ ticketData.ticketTypeName }}</a></span>
                <span class="mr-4"><b>Sede:</b> <a href="#">{{ ticketData.campusName }}</a></span>
                <span class="mr-4"><b>Área:</b> <a href="#">{{ ticketData.dependencyName }}</a></span>
            </b-col>
        </b-row>
        <hr class="mb-4">
        <b-row>
            <b-col cols="10" sm="10" md="10" lg="10" xl="10">
                <!-- Descripción del ticket -->
                <b-card class="mb-4">
                    <template #header>
                        <b>Descripción:</b>
                    </template>
                    <b-card-text>{{ ticketData.description }}</b-card-text>
                    <template #footer>     
                        <div class="mb-3">
                            <span><b>Datos de Contacto:</b></span>
                            <div class="ml-4">
                                <p class="p-0 m-0"><b>Teléfono:</b> {{ ticketData.phone }}</p>
                                <p class="p-0 m-0"><b>Email:</b> {{ ticketData.email }}</p>
                                <p class="p-0 m-0"><b>Remoto / VNC:</b> {{ ticketData.remote }}</p>
                            </div>
                        </div>           
                        <div class="mb-3" v-if="ticketData.attachments.length > 0">
                            <span><b>Listado de Adjuntos:</b></span>
                            <div class="ml-4">
                                <ul class="mt-2">
                                    <li v-for="file in ticketData.attachments" :key="file.id">
                                        <a :href="file.url" target="_blank">{{ file.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <b-button v-if="!addingNewComment" size="sm" class="btn-primary" @click="addingNewComment=!addingNewComment">Nuevo Comentario</b-button>
                    </template>
                </b-card>
                <!-- Fin descripción -->
                <!-- Formulario de nuevo comentario -->
                <b-card class="ml-3 mb-4" v-if="addingNewComment">
                    <template #header>
                        <b>Agregar comentario:</b>
                    </template>
                    <b-form-textarea rows="9" placeholder="Escribe aquí..." v-model="newComment"></b-form-textarea>
                    <div class="mt-2 text-center">
                        <b-button class="btn-primary mr-4" size="sm" @click="saveComment()">Agregar</b-button>
                        <b-button class="btn-gray" size="sm" @click="addingNewComment=!addingNewComment">Cancelar</b-button>
                    </div>
                </b-card>
                <!-- Fin Formulario de nuevo comentario -->
                <!-- Listado de comentarios -->
                <b-card class="ml-3" v-if="ticketData.comments.length > 0">
                    <template #header>
                        <b>Comentarios</b>
                    </template>
                    <b-card no-body v-for="comment in ticketData.comments" :key="comment.id" class="mb-3">
                        <b-card-header header-tag="header" class="p-2" role="tab">
                            <b-icon class="mr-4" icon="envelope-open" scale="1.2" variant="success"></b-icon><span class="mr-4"><b>{{ comment.nameUser }}</b></span><span>{{ comment.date | formatDate }}</span>
                        </b-card-header>
                        <b-card-body>
                            <b-card-text>{{ comment.description }}</b-card-text>
                        </b-card-body>
                    </b-card>            
                </b-card>
                <!-- Fin Listado de comentarios -->
            </b-col>
            <b-col>
                <b>Estado: </b><br>
                <h4> 
                    <b-badge pill :variant="$store.getters.variantState(ticketData.state)">
                        {{ ticketData.state }}
                    </b-badge>
                    <b-icon v-if="checkPermission('gestionar-ticket') && (ticketData.responsibleId && $store.state.userLogged.userId == ticketData.responsibleId) && ticketData.state != 'Finalizado'" @click="showModalChangeState(ticketData.state)" style="cursor: pointer;" class="ml-2" icon="arrow-clockwise"></b-icon>
                </h4>
                <br><b>Prioridad: </b><br>
                <h5>
                    <b-badge pill :variant="$store.getters.variantPriority(ticketData.priority)">
                        {{ ticketData.priority }}
                    </b-badge>
                </h5>
                <br><b>Responsable:</b><br>
                <h5>
                    <b-badge pill :variant="ticketData.responsibleName ? 'success' : 'danger'">
                        {{ticketData.responsibleName ? ticketData.responsibleName : 'Sin Asignar'}}
                    </b-badge>
                    <b-icon v-if="checkPermission('asignar-responsable')  && ticketData.state != 'Finalizado'" @click="showModalAssign()" style="cursor: pointer;" class="ml-2" icon="arrow-clockwise"></b-icon>
                </h5>
            </b-col>
        </b-row>
        
        <b-modal size="md" centered  id="modal-change-state" :title="changeStateModal.title" @ok="changeStateModal.handle()" ok-title="Aceptar" cancel-title="Cancelar">
            <div class="test-justify" v-html="changeStateModal.text"></div>
        </b-modal>
        <b-modal size="md" centered  id="modal-assign-ticket" :title="assignTicketModal.title" @ok="assignTicketModal.handle()" ok-title="Aceptar" cancel-title="Cancelar">
            <!-- <label for=""><b>{{assignTicketModal.text}}</b></label> -->
            <b-form-select
                v-model="newResponsible"
                :options="assignTicketModal.responsibles"
                placeholder="Escriba aquí..."
            ></b-form-select>
        </b-modal>
    </b-container>
</template>
<script>
import TicketsService from '@/services/TicketsService'
import UsersService from '@/services/UsersService'
import moment from 'moment'
export default {
    components: {
    },
    data: () => ({
        ticketData: {},
        newComment: '',
        addingNewComment: false,
        changeStateModal: {
            title: '',
            text: '',
            handle: null
        },
        assignTicketModal: {
            title: '',
            text: '',
            handle: null,
            responsibles: []
        },
        newResponsible: null,
        usersList: []
    }),
    async mounted() {
        this.getTicketData();
        this.usersList = await UsersService.getResponsibles()
        this.usersList = this.usersList.map(e => {
            return {
                value: e.id,
                text: e.name
            }
        })
        this.usersList.unshift({
            value: null,
            text: 'Seleccione...'
        })
    },
    computed: {
        
    },
    methods: {
        async getTicketData(){
            this.ticketData = await TicketsService.getTicketDetail(this.$route.params.id);
            if(this.$store.state.userLogged.roleId == 3){
                if(this.ticketData.userId != this.$store.state.userLogged.userId){
                    this.$router.replace('/tickets');
                }
            }
        },
        async saveComment(){
            let commentToSave = {
                description: this.newComment
            }
            let response = await TicketsService.addCommentToTicket(this.$route.params.id,commentToSave);
            if(response.error){
                console.log('Error',response.message)
                this.$newSnackbarError('No se pudo guardar el comentario.')
                return;
            }
            this.$newSnackbarSuccess(response.message)
            this.addingNewComment = false;
            this.newComment = '';
            this.getTicketData();
        },
        async showModalChangeState(state){
            switch (state) {
                case 'Pendiente':
                    this.changeStateModal.title = 'Cambio de estado del ticket';
                    this.changeStateModal.text = `Confirma que desea cambiar el ticket <b># ${this.$route.params.id}</b> de <b>${state}</b> a <b>Desarrollo</b>`;
                    this.changeStateModal.handle = this.inProgressTicket
                    this.$bvModal.show('modal-change-state')                  
                    break;
                case 'Desarrollo':
                    this.changeStateModal.title = 'Finalizar ticket';
                    this.changeStateModal.text = `Confirma que desea dar por solucionado el ticket <b># ${this.$route.params.id}</b>`;
                    this.changeStateModal.handle = this.closeTicket
                    this.$bvModal.show('modal-change-state')
                    break;
                default:
                    break;
            }
        },
        async closeTicket(){
            let response = await TicketsService.closeTicket(this.$route.params.id);
            if(response.error){
                console.log('Error',response.message)
                this.$newSnackbarError('No se pudo cerrar el ticket.')
                return;
            }
            this.$bvModal.hide('modal-change-state')
            this.$newSnackbarSuccess(response.message)
            this.getTicketData();
        },
        async inProgressTicket(){
            let response = await TicketsService.inProgressTicket(this.$route.params.id);
            if(response.error){
                console.log('Error',response.message)
                this.$newSnackbarError('No se pudo cambiar el estado del ticket.')
                return;
            }
            this.$bvModal.hide('modal-change-state')
            this.$newSnackbarSuccess(response.message)
            this.getTicketData();
        },
        async assignTicket(){
            let dataToSend = {
                responsible: this.newResponsible
            }
            let response = await TicketsService.assignTicket(this.$route.params.id,dataToSend);
            if(response.error){
                console.log('Error',response.message)
                this.$newSnackbarError('No se pudo cambiar el estado del ticket.')
                return;
            }
            this.$bvModal.hide('modal-change-state')
            this.$newSnackbarSuccess(response.message)
            this.getTicketData();
        },
        async showModalAssign(){
            this.assignTicketModal.title = `Asignar ticket # ${this.$route.params.id} a:`;
            // this.assignTicketModal.text = `Asignar ticket <b># ${this.$route.params.id}</b> a:`;
            this.assignTicketModal.handle = this.assignTicket;
            this.assignTicketModal.responsibles = this.usersList;
            this.$bvModal.show('modal-assign-ticket')      
        }
    },
    watch: {
        
    },
    filters: {
        formatDate(date){
            return moment(date).format('MMM D, YYYY h:mm A')
        }
    }
};
</script>
<style lang="scss" scoped>

</style>