<template>
    <b-container fluid>
        
        <b-row align-h="center">
            <b-col cols="12">
                <h4>Listado de Tickets</h4>
                <hr>
                <!-- <p><b>Filtros:</b></p> -->
                <b-row>
                    <b-col md="3" lg="3">
                        <label for="">Desde:</label>
                        <b-form-datepicker v-model="filters.dateIni" class="mb-2"></b-form-datepicker>
                    </b-col>
                    <b-col md="3" lg="3">
                        <label for="">Hasta:</label>
                        <b-form-datepicker v-model="filters.dateEnd" class="mb-2"></b-form-datepicker>
                    </b-col>
                    <b-col md="3" lg="3" v-if="$store.state.userLogged.roleId != 3">
                        <label for="">Sede:</label>
                        <b-form-select
                            v-model="filters.campus"
                            :options="campusList"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-select>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col md="3" >
                        <b-button variant="primary" @click="getTickets()" class="mr-3">Listar <b-icon icon="list-stars"></b-icon></b-button>
                        <b-button v-if="$store.state.userLogged.roleId != 3" variant="success" @click="exportExcel()">Descargar Excel <b-icon icon="arrow-down-circle-fill"></b-icon></b-button>
                    </b-col>
                    <b-col md="3">
                        <b-form-checkbox @change="getTickets()" v-model="showingCloseTickets">
                            Ver Finalizados
                        </b-form-checkbox>
                        <b-form-checkbox @change="getTickets()" v-model="showingMyTickets" v-if="$store.state.userLogged.roleId != 3">
                            Mis Tickets Asignados
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <hr>
            </b-col>
        </b-row>
        <b-row align-h="center">
            <b-col cols="12">
                <b-table 
                    id="myTable" 
                    bordered 
                    hover 
                    small 
                    head-variant="dark" 
                    responsive="md"
                    :items="ticketsList" 
                    :fields="fields" 
                    @row-clicked="viewDetails" 
                >
                    <!-- <template #cell(initialDate)="data">
                        {{ data.item.initialDate | formatDate }}
                    </template> -->
                    <template #cell(createdDate)="data">
                        {{ data.item.createdDate | formatDate }}
                    </template>
                    <template #cell(finalDate)="data">
                        {{ data.item.finalDate | formatDate }}
                    </template>
                    <template #cell(state)="data">
                        <b-badge pill :variant="$store.getters.variantState(data.item.state)">{{ data.item.state }}</b-badge>
                    </template>
                    <template #cell(priority)="data">
                        <b-badge pill :variant="$store.getters.variantPriority(data.item.priority)">{{ data.item.priority }}</b-badge>
                    </template>
                    <template #cell(responsibleName)="data">
                        <b-badge v-if="!data.item.responsibleName" variant="danger" pill>Sin Asignar</b-badge>
                        <span v-else>{{ data.item.responsibleName }}</span>
                    </template>
                    <!-- <template #cell(administration)="data">
                        <div class="text-center">
                            <b-icon scale="1.5" variant="info" icon="eye-fill" class="mr-3" style="cursor: pointer" title="Detalle" @click="viewDetails(data.item.id)"></b-icon>
                            <b-icon v-if="checkPermission('gestionar-ticket')" scale="1.5" variant="info" icon="person-fill" class="mr-4" style="cursor: pointer" title="Gestionar"></b-icon> 
                            <b-icon v-if="checkPermission('eliminar-ticket')" scale="1.5" variant="danger" icon="trash-fill" style="cursor: pointer" title="Eliminar"></b-icon> 
                        </div>
                    </template> -->
                </b-table>
                <b-pagination
                    class="text-center"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    align="center"
                    @change="goToPage($event)"
                ></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template> 
  
<script>
import moment from 'moment'
import XLSX from "xlsx";
import TicketService from '@/services/TicketsService'
import CampusService from '@/services/CampusService'

export default {
    components: {
    },
    data: () => ({
        ticketsList: [],
        fields: [
            { key: 'id', label: '#', sortable: true, thStyle: { width: "3%" } },
            { key: 'priority', label: 'Prioridad', sortable: true, thStyle: { width: "5%" } },
            { key: 'state', label: 'Estado', sortable: true, thStyle: { width: "5%" }},
            { key: 'createdDate', label: 'Creado el', sortable: true, thStyle: { width: "8%" } },
            // { key: 'initialDate', label: 'Gestionado', sortable: true, thStyle: { width: "8%" } },
            { key: 'finalDate', label: 'Cerrado el', sortable: true, thStyle: { width: "8%" } },
            { key: 'days', label: 'Días', sortable: true, thStyle: { width: "3%" }},
            { key: 'ticketTypeName', label: 'Tipo', sortable: true, thStyle: { width: "8%" } },
            { key: 'campusName', label: 'Sede', sortable: true, thStyle: { width: "9%" } },
            { key: 'dependencyName', label: 'Area', sortable: true, thStyle: { width: "9%" } },
            { key: 'responsibleName', label: 'Responsable', sortable: true, thStyle: { width: "10%" } },
            // { key: 'administration', label: ''},
        ],
        currentPage: 0,
        rows: 0,
        perPage: 0,
        showingCloseTickets: false,
        showingMyTickets: false,
        filters: {
            dateIni: null,
            dateEnd: null,
            campus: null,
            exportExcel: false
        },
        dataExport: []
    }),
    mounted() {
        this.getAllTickets()
        this.getCampusList();
    },
    computed: {
    },
    methods: {
        async getAllTickets(page){
            this.ticketsList = [];
            const filters = {
                ...this.filters,
                responsible: this.showingMyTickets ? this.$store.state.userLogged.userId : null
            }
            let responseData = await TicketService.getAll(page ?? 1,filters);
            if(this.filters.exportExcel){
                this.dataExport = responseData
                return;
            }
            this.perPage = responseData.per_page;
            this.rows = responseData.total;
            this.ticketsList = responseData.data.map(e => {
                let days = 1;
                if(e.state == 'Finalizado'){
                    let createdDate = new Date(e.createdDate).getTime()
                    let finalDate = new Date(e.finalDate).getTime()
                    days = Math.ceil((finalDate - createdDate)/86400000)
                }else{
                    let currentDate = new Date().getTime()
                    let createdDate = new Date(e.createdDate).getTime()
                    days = Math.ceil((currentDate - createdDate)/86400000)
                }
                return {
                    ...e,
                    days: days == 0 ? 1 : days
                }
            });
            this.currentPage = responseData.current_page;
        },
        async getAllTicketsWithClosed(page){
            this.ticketsList = [];
            const filters = {
                ...this.filters,
                responsible: this.showingMyTickets ? this.$store.state.userLogged.userId : null
            }
            let responseData = await TicketService.getAllWithClosed(page ?? 1,filters);
            if(this.filters.exportExcel){
                this.dataExport = responseData
                return;
            }
            this.perPage = responseData.per_page;
            this.rows = responseData.total;
            this.ticketsList = responseData.data.map(e => {
                let days = 1;
                if(e.state == 'Finalizado'){
                    let createdDate = new Date(e.createdDate).getTime()
                    let finalDate = new Date(e.finalDate).getTime()
                    days = Math.ceil((finalDate - createdDate)/86400000)
                }else{
                    let currentDate = new Date().getTime()
                    let createdDate = new Date(e.createdDate).getTime()
                    days = Math.ceil((currentDate - createdDate)/86400000)
                }
                return {
                    ...e,
                    days: days == 0 ? 1 : days
                }
            });
            this.currentPage = responseData.current_page;
        },
        viewDetails(item){
            // this.$router.push(`/tickets/${item.id}/details`)
            let route = this.$router.resolve({path: `/tickets/${item.id}/details`})
            window.open(route.href, '_blank');
        },
        goToPage(page){
            this.currentPage = page;
            this.getTickets(page)
        },
        async getTickets(page){
            if(this.showingCloseTickets){
                await this.getAllTicketsWithClosed(page)
            }else{
                await this.getAllTickets(page)
            }
        },
        async getCampusList(){
            const response = await CampusService.getAll()
            this.campusList = response.map(e => {
                return {
                    value: e.id,
                    text: e.name
                }
            })
            this.campusList.unshift({
            value: null,
            text: 'Todas'
        })
        },
        async exportExcel(){
            this.filters.exportExcel = true
            await this.getTickets();
            let data = XLSX.utils.json_to_sheet(this.dataExport);
            const workbook = XLSX.utils.book_new();
            let fileName = ''
            if(this.filters.dateIni && this.filters.dateEnd){
                fileName = `tickets_${this.filters.dateIni}_${this.filters.dateEnd}`;
            }else{
                fileName = `tickets`;
            }
            XLSX.utils.book_append_sheet(workbook, data, fileName);
            XLSX.writeFile(workbook, `${fileName}.xlsx`);
            this.$newSnackbarSuccess('Archivo generado con éxito')
        }
    },
    watch: {
        
    },
    filters: {
        formatDate(date){
            if(!date){
                return ''
            }
            return moment(date).format('YYYY-MM-DD hh:mm:ss a')
        }
    }
};
</script>
  
<style lang="scss" scoped>
.columna-dias {
  width: 4% !important; /* Ajusta el ancho según tus necesidades */
}
</style>