import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Auth/LoginView";
import Register from "@/views/Auth/RegisterView";
import store from '@/store'
import moment from 'moment';
import TemplateHome from '../views/Templates/Index.vue'
import Tickets from '../views/Tickets/Index.vue'
import CreateTicket from '../views/Tickets/Create.vue'
import DetailsTicket from '../views/Tickets/Details.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: '/novedades'
  // },
  {
    path: '/',
    meta: { requireAuth: true },
    component: TemplateHome,
    children: [
      // {
      //   path: '/',
      //   redirect: '/tickets'
      // },
      {
        path: '/tickets',
        component: Tickets
      },
      {
        path: '/tickets/create',
        component: CreateTicket
      },
      {
        path: '/tickets/:id/details',
        component: DetailsTicket
      },
      {
        path: '/admin',
        component: Tickets
      },
    ]
  },
  {
    path: "/login",
    component: Login
  },
  {
    path: "/register",
    component: Register
  },
]
// esto es una prueba
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const protectedRoute = to.matched.some(record => record.meta.requireAuth)
  let userLogged = store.getters.getUserLogged ?? null
  let timeElapsed = userLogged ? (moment() - userLogged.dateLogged) : 0;

  if (timeElapsed > 28800000) {
    store.dispatch('logout')
    next({ path: '/login' })
  }

  if (protectedRoute && !store.getters.isLogged) {   //28800000 son 8 horas de inactividad y se cierra la sesion
    next({ path: '/login' })
  } else {
    next()
  }
})

export default router
