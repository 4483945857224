<template>
  <b-container>
    <b-row align-h="center" class="mt-5">
      <b-col>
        <b-row>
        <b-col class="text-center">
            <img class="mr-2" style="height: 5rem;" src="../../assets/LOGO_helpdesk.png" alt="Alianza Diagnóstica">
            <h1 style="font-family:fantasy">HelpDesk</h1>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5">
        <b-col cols="5">
          <b-input-group class="mb-4">
            <b-input-group-prepend is-text>
              <b-icon icon="person"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" placeholder="Usuario" required v-model="form.user"></b-form-input>
          </b-input-group>
          <b-input-group class="mb-4">
            <b-input-group-prepend is-text>
              <b-icon icon="lock"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="password" placeholder="Clave" required v-model="form.password"></b-form-input>
          </b-input-group>
          <b-form-group>
            <b-button block variant="primary" @click="login()">Ingresar</b-button>
          </b-form-group>
        </b-col>
      </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import auth from "./services/Auth";
export default {

  data: () => ({
    form: {
      user: "",
      password: "",
    },
    errors: [],
    isValidForm: false,
    error_message: ""
  }),
  beforeMount() {
    if (this.$store.getters.isLogged) {
      this.$router.replace("/");
    }
  },
  mounted() {
  },
  methods: {
    async login() {
      const data = {
        user: this.form.user,
        password: this.form.password
      };
      auth.login(data).then(() => {
        this.$router.push("/");
      }).catch(error => {
        console.log('error', error)
        this.$newSnackbarWarning(error)
      });
    }
  },
  watch: {
    form: {
      handler(newForm) {
        this.isValidForm = newForm.user !== "" && newForm.password !== "";
      },
      deep: true
    }
  },
};
</script>

<style lang="scss"></style>