<template>
  <b-navbar type="dark" variant="dark" >
    <img height="40rem" src="../assets/LOGO_helpdesk.png" alt="Alianza Diangnística">
      <b-navbar-brand id="text-brand" class="ml-5" ><b>HelpDesk</b></b-navbar-brand>
    <b-button id="btn-logout" size="sm" variant="danger" @click="logout()" >Salir
    </b-button>
  </b-navbar>
</template>
<script>
import auth from "../views/Auth/services/Auth"
export default {
  name: 'Navbar',
  data() {
    return {
      iconToggleSidebar: 'chevron-left'
    }
  },
  methods: {
    async logout() {
      auth.logout().then(() => {
        this.$router.push('/login')
      }).catch(err => {
        console.log('err', err)
      })

    },
    async toggleSidebar() {
      await this.$store.dispatch('toggleSidebar')
    }
  }
}
</script>

<style scoped>

#text-brand{
  display: block;
 }

#navbar-desktop{
  display: block;
 }

#navbar-mobile{
  display: none;
 }

@media (max-width: 400px) {

#text-brand{
  display: none;
 }

 #navbar-mobile{
  display: block;
 }

 #navbar-desktop{
  display: none;
 }

}



</style>