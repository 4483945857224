<template>
  <div id="app">
    <Snackbar v-if="$store.state.snackbarData.message" :text="$store.state.snackbarData.message"
      :variant="$store.state.snackbarData.variant"></Snackbar>
      <router-view></router-view>
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
  data() {
    return {
    }
  },
  created(){
    
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "node_modules/vue-bootstrap-sidebar/src/scss/default-theme";
@import './app.scss';

// * {
//   box-sizing: content-box;
//   font-family: 'Open Sans', sans-serif !important;
//   font-weight: 400;
//   font-size: 12px;
// }

html{
  font-size: 12px;
}

body {
  background-color: #F4F7F9 !important;
}


.general-button {
  color: #9b9ead !important;
  box-shadow: 0 3px rgb(199, 204, 208);
  background-color: #e5eaee !important;
  border: none !important;
  font-weight: 500 !important;
}

.general-button:hover {
  background-color: $adx-primary !important;
  border: none !important;
  color: white !important;
}


.profile {
  display: none;
}


.card-title {
  color: $adx-primary;
  font-size: 1.5rem;
  font-weight: bold;
}

.custom-table .thead-dark th {
  background-color: $adx-primary !important;
  /* Cambia el color de fondo del encabezado */
  color: #fff;
  border-color: #DFDFDF !important;
  /* Cambia el color del texto del encabezado */
}

.main-card {
  border: none;
  padding: 0px;
  margin: 0;
  box-shadow: 0px 3px 5px 1px #ccc !important;
  height: 100vh;
  min-width: 700px;
}

.btn-primary {
  background-color: $adx-primary !important;
  border: none !important;
  color: white
}

.bg-primary {
  background-color: $adx-primary !important;
}

.btn-success {
  background-color: $adx-success !important;
  border: none !important;
}

.btn-gray{
  background-color: $adx-gray !important;
  color: #323235 !important;
  border: solid 1px gray;
}

.text-info {
  color: $adx-info;
}

.multiselect__tag,
.multiselect__option--highlight {
  background-color: $adx-success !important;
}

#btn-logout {
  margin-left: auto;
  font-size: .8em;
}


@media screen and (max-width: 1023px) {
  html{
    font-size: 8px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1600px)  {
  html{
    font-size: 10px !important;
  }
}
@media screen and (min-width: 1600px) {
  html{
    font-size: 16px !important;
  }
}
</style>
