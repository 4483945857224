<template>
    <b-container>
        <b-row align-h="center">
            <b-col cols="8">
                <h4 class="text-center">Creación de un nuevo Ticket</h4>
                <hr>
                <p class="mb-2">Los campos marcados con <span class="required-symbol">(*)</span> son obligatorios:</p>
                <b-form>
                    <b-row>
                        <!-- <b-col class="mb-3" cols="12" md="12" lg="12" xl="12">
                            <label for=""><b>Título o Asunto:</b></label>
                            <b-form-input
                            v-model="form.title"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-input>
                        </b-col> -->
                        <b-col class="mb-3" cols="12" md="6" lg="6" xl="6">
                            <label for=""><b>Tipo Ticket: <span class="required-symbol">(*)</span></b></label>
                            <b-form-select
                            v-model="form.ticketTypeId"
                            :options="ticketsTypeList"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-select>
                        </b-col>
                        <b-col class="mb-3" cols="12" md="6" lg="6" xl="6">
                            <label for=""><b>Sede: <span class="required-symbol">(*)</span></b></label>
                            <b-form-select
                            v-model="form.campusId"
                            :options="campusList"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-select>
                        </b-col>
                        <b-col class="mb-3" cols="12" md="6" lg="6" xl="6">
                            <label for=""><b>Area: <span class="required-symbol">(*)</span></b></label>
                            <b-form-select
                            v-model="form.dependencyId"
                            :options="dependenciesList"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-select>
                        </b-col>
                        <b-col class="mb-3" cols="12" md="6" lg="6" xl="6">
                            <label for=""><b>Prioridad: <span class="required-symbol">(*)</span></b></label>
                            <b-form-select
                            v-model="form.priority"
                            :options="prioritiesList"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-select>
                        </b-col>
                        <b-col class="mb-3" cols="12" md="12" lg="12" xl="12">
                            <label for=""><b>Correo:</b></label>
                                <b-form-input
                                type="email"
                                v-model="form.email"
                                placeholder="Escriba aquí..."
                                ></b-form-input>
                                <p v-if="!emailInputState && form.email.length > 0" style="color: red">
                                    Ingresa una dirección de correo electrónico válida.
                                </p>
                        </b-col>
                        <b-col class="mb-3" cols="12" md="6" lg="6" xl="6">
                            <label for=""><b>Teléfono / Celular: <span class="required-symbol">(*)</span></b></label>
                            <b-form-input
                            v-model="form.phone"
                            placeholder="Escriba aquí..."
                            required
                            type="number"
                            ></b-form-input>
                        </b-col>
                        <b-col class="mb-3" cols="12" md="6" lg="6" xl="6">
                            <label for=""><b>VNC:</b></label>
                            <b-form-input
                            v-model="form.remote"
                            placeholder="Escriba aquí..."
                            required
                            ></b-form-input>
                        </b-col>
                        <b-col class="mb-3" cols="12">
                            <label for=""><b>Descripción del caso: <span class="required-symbol">(*)</span></b></label>
                            <b-form-textarea
                            v-model="form.description"
                            placeholder="Escriba aquí..."
                            required
                            rows="10"
                            ></b-form-textarea>
                        </b-col>
                        <b-col class="mb-3" cols="12">
                            <label for=""><b>Adjuntar documentos o imágenes:</b></label>
                            <b-form-file
                            v-model="files"
                            :state="Boolean(files)"
                            multiple
                            placeholder="Seleccione o arrastre archivos aquí..."
                            drop-placeholder="Drop file here..."
                            >
                                <template slot="file-name" slot-scope="{ names }">
                                    <b-badge variant="primary">{{ names[0] }}</b-badge>
                                    <b-badge v-if="names.length > 1" variant="secondary" class="ml-1">
                                    + {{ names.length - 1 }} archivos más
                                    </b-badge>
                                </template>
                            </b-form-file>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button variant="primary" @click="saveTicket()" :disabled="!validateForm">Guardar</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import CampusService from '@/services/CampusService'
import PriorityService from '@/services/PriorityService'
import DependencyService from '@/services/DependencyService'
import TicketsService from '@/services/TicketsService';
import TicketsTypeService from '@/services/TicketsTypeService';

export default {
    components: {
    },
    data: () => ({
        form: {
            campusId: null,
            dependencyId: null,
            priority: null,
            description: '',
            ticketTypeId: null,
            email: '',
            remote: '',
            phone: null
        },
        files: null,
        campusList: [],
        dependenciesList: [],
        prioritiesList: [],
        ticketsTypeList: [],
        itemListNull: {
            value: null,
            text: 'Seleccione...'
        }
    }),
    mounted() {
        this.getCampusList();
        this.getDependenciesList();
        this.getPrioritiesList();
        this.getTicketsTypeList();
    },
    computed: {
        validateForm(){
            if(this.form.campusId && this.form.dependencyId && this.form.priority && this.form.description != '' && this.form.ticketTypeId && this.form.phone){
                return true;
            }
            return false
        },
        emailInputState(){
            return this.isValidEmail(this.form.email)
        }
    },
    methods: {
        async getCampusList(){
            const response = await CampusService.getAll()
            this.campusList = response.map(e => {
                return {
                    value: e.id,
                    text: e.name
                }
            })
            this.campusList.unshift(this.itemListNull)
        },
        async getDependenciesList(){
            const response = await DependencyService.getAll()
            this.dependenciesList = response.map(e => {
                return {
                    value: e.id,
                    text: e.name
                }
            })
            this.dependenciesList.unshift(this.itemListNull)
        },
        async getPrioritiesList(){
            const response = await PriorityService.getAll()
            this.prioritiesList = response.map(e => {
                return {
                    value: e,
                    text: e
                }
            })
            this.prioritiesList.unshift(this.itemListNull)
        },
        async getTicketsTypeList(){
            const response = await TicketsTypeService.getAll()
            this.ticketsTypeList = response.map(e => {
                return {
                    value: e.id,
                    text: e.name
                }
            })
            this.ticketsTypeList.unshift(this.itemListNull)
        },
        async saveTicket(){
            let formData = new FormData();
            for (const prop in this.form) {
                formData.append(`${prop}`, `${this.form[prop]}`);
            }
            let counter = 1;
            if(this.files){
                this.files.forEach((el) => {
                formData.append(`file_${counter}`, el);
                counter = counter + 1;
                });
            }      
            const rta = await TicketsService.saveTicket(formData)
            if(rta.error){
                this.$newSnackbarError('No se guardó el ticket')
                console.error(rta.message)
                return;
            }
            // this.$newSnackbarSuccess(rta.message)
            await this.showMsgBoxConfirm(rta.message);
            this.$router.replace('/tickets')
        },
        async showMsgBoxConfirm(message) {
            this.boxTwo = ''
            await this.$bvModal.msgBoxOk(message, {
            title: 'Confirmación de Ticket Generado',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
            okTitle: 'Cerrar'
            })
        },
        isValidEmail(email) {
            console.log('validando',email)
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return regex.test(email);
        },
    },
    watch: {
        
    },
};
</script>
<style lang="scss" scoped>
.required-symbol{
    color: red;
}
</style>