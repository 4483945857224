<template>
    <div>
      <NavBar></NavBar>
      <div class="text-right mr-5 my-0">
        <p style="margin:0px; padding: 0px; font-size: smaller; font-style: italic; font-weight: bold;">Usuario: {{
          `${$store.getters.getUserLogged
            ?
            $store.getters.getUserLogged.username : ''}` }}</p>
      </div>
      <b-container fluid>
        <b-row class="mb-2">
          <b-col>
            <b-button to="/tickets" variant="primary" class="mr-1 p-2 btn-menu">
              <img src="../../assets/house.png" alt="Imagen" class="mr-1"/>Inicio
            </b-button>
            <b-button to="/tickets/create" variant="primary" class="mr-1 p-2 btn-menu">
              <img src="../../assets/support.png" alt="Imagen" class="mr-1"/>Nuevo Ticket
            </b-button>            
          </b-col>
        </b-row>
        <b-row align-h="center" align-v="center">
          <b-col cols="12" sm="12" lg="12" xl="12">
            <b-card class="main-card">
              <router-view></router-view>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template> 
  
<script>
import NavBar from "@/components/Navbar.vue"

export default {
    components: {
        NavBar,
    },
    data: () => ({

    }),
    mounted() {
    },
    methods: {

    },
    watch: {
        
    },
};
</script>
  
<style lang="scss" scoped>


.btn-menu img{
  height: 2rem
}
</style>